import { Select, MenuItem } from '@material-ui/core';
import React, { Component } from 'react';
import { getControlAreas, GetControlAreaById } from 'services/areas.service';

export default class ParentSelector extends Component<any, any> {
    _isMounted: boolean = false;
    constructor(props: any) {
        super(props);
        this.state = {
            data: [],
            selected: { selected: null },
        };
    }

    componentDidMount() {
        getControlAreas().then((res: any) => {
            let areas: any = res.data ? res.data : res;
            if (this._isMounted) {
                this.setState({
                    data: areas,
                    selected: this.props.value || null,
                });
            }
        });
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleChange = (event: any) => {
        let area = GetControlAreaById(event.target.value);
        this.setState({ selected: area });
        this.props.handleChange(area)
    };

    renderOptions() {
        return this.state && this.state.data && this.state.data.length &&
            this.state.data.map((dt: any, i: any) => {
                return (
                    <MenuItem key={dt._id} value={dt._id}>{dt.name}</MenuItem>
                );
            });
    }

    render() {
        return (
            <div className="padd50">
                <Select className="width50"
                    value={this.props.value._id ? this.props.value._id : { name: 'No Parent' }}
                    onChange={this.handleChange}>
                    {this.renderOptions()}
                </Select>
            </div>
        );
    }
}
