import React from 'react';
import { ControlArea } from 'enum/types';
import styled from 'styled-components';

const CurrentMinimum = ({ area, parent }: { area: ControlArea; parent?: boolean }) => {
  const currentMinimum = `${area.currentResidents}/${area.minimumResidents}`;
  const currentMinimumRatio = Math.min(Math.round((area.currentResidents / area.minimumResidents) * 100), 100);

  return (
    <FlexContainer>
      {parent ? (
        <Bolder>{area.currentResidents}</Bolder>
      ) : (
        <>
          <Bolder>{currentMinimum}</Bolder>
          {`${currentMinimumRatio}%`}
        </>
      )}
    </FlexContainer>
  );
};

const FlexContainer = styled.div`
  display: flex;
`;

const Bolder = styled.div`
  font-weight: bolder;
  padding-right: 0.5rem;
`;

export default CurrentMinimum;
