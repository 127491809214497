import React, { useState } from 'react';
import MaterialTable, { Options } from 'material-table';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {
  childrenColumn,
  currentMinimumColumn,
  deleteAreaColumn,
  premiumColumn,
  statusColumn,
  updatedColumn,
  zonesColumn,
} from 'component/areas/TableColumns';
import styled from 'styled-components';
import { ProcessedAreas } from 'utils/processedAreas';
import { ControlArea } from 'enum/types';

const options: Options<ControlArea> = {
  pageSizeOptions: [10, 30, 50, 100],
  pageSize: 15,
};

const AreaTable = ({
  processedAreas: { parentAreas, processedAreas, neighborhoods, usersTotal },
}: {
  processedAreas: ProcessedAreas;
}) => {
  const [deleteHovered, setDeleteHovered] = useState(false);
  const parentAreasCount = parentAreas && Object.keys(parentAreas).length;

  const handleOnRowClick = (event?: React.MouseEvent, rowData?: { _id: string }) => {
    if (deleteHovered) return;
    if (rowData && event) {
      window.location.href = '/update/' + rowData._id;
    }
  };

  return (
    <AreaTableContainer>
      <StyledUsersInfo>
        {neighborhoods}/{processedAreas.length} Active neighborhoods in {parentAreasCount} areas with {usersTotal}{' '}
        users.
      </StyledUsersInfo>
      <MaterialTable
        data={processedAreas}
        title="Regions"
        options={options}
        onRowClick={handleOnRowClick}
        columns={[
          zonesColumn(processedAreas, parentAreas),
          childrenColumn(processedAreas, parentAreas),
          statusColumn(),
          currentMinimumColumn(processedAreas, parentAreas),
          premiumColumn(processedAreas, parentAreas),
          updatedColumn(),
          deleteAreaColumn(processedAreas, setDeleteHovered),
        ]}
      />
    </AreaTableContainer>
  );
};

const StyledUsersInfo = styled.div`
  padding: 1.2rem 0;
`;

const AreaTableContainer = styled.div`
  .MuiToolbar-root {
    padding-left: 2rem !important;
    padding-bottom: 1.2rem;
    border-bottom: 1.9px solid;
    border-bottom-color: #e7e7e7;
  }

  .MuiTypography-h6 {
    font-weight: bolder;
  }

  .MuiTablePagination-toolbar {
    padding: 0 !important;
  }
`;

export default AreaTable;
