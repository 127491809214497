import React, { Component } from 'react';
import ReactMapboxGl from 'react-mapbox-gl';
import DrawControl from 'react-mapbox-gl-draw';
import { Config } from 'utils/config';
import center from '@turf/center';
import { ControlArea } from 'enum/types';
import DRAW_MAP_STYLE from './const.draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import mapboxgl from 'mapbox-gl';
import { DeleteControlAreaGeozone } from 'services/areas.service';
import { confirmAlert } from 'react-confirm-alert';

const DEFAULT_POLYGON_COLOR = '#00FFF0';
const Map = ReactMapboxGl({
  accessToken: Config.MAPBOX_ACCESS_TOKEN,
});
export class AreaMap extends Component<any, any> {
  public drawControl: any;
  public coordinates: [number, number] = [32, 33];
  public zoom: [number] = [6];
  public map: any;
  public controls: any = {
    point: false,
    line_string: false,
    polygon: true,
    trash: true,
    combine_features: false,
    uncombine_features: false,
  };
  isDrawInited: any;
  drawAbleMultiPolygon: any;
  tooltipFeature: any;
  popup: any;
  firstAdd: any;
  // can be inited with props as parameter
  constructor(props: any) {
    super(props);
    this.state = {
      geozone: {
        type: '',
        coordinates: [],
      },
    };
    this.onDrawCreate = this.onDrawCreate.bind(this);
    this.onDrawUpdated = this.onDrawUpdated.bind(this);
    this.onDrawDeleted = this.onDrawDeleted.bind(this);
  }

  componentDidMount() {
    if (this.needToDraw()) {
      this.addPolygons();
    }
  }

  componentDidUpdate(prevProps: any) {
    // Typical usage (don't forget to compare props):
    if (
      (this.props.polyFeature && this.props.polyFeature.type && prevProps.polyFeature && !prevProps.polyFeature.type) ||
      (this.props.polyFeature &&
        this.props.polyFeature.length &&
        prevProps.polyFeature &&
        !prevProps.polyFeature.length) ||
      this.props.polyFeature !== prevProps.polyFeature
    ) {
      this.addPolygons();
    }
  }

  needToDraw() {
    // eslint-disable-next-line
    return (
      (this.props.polyFeature && this.props.polyFeature.type) ||
      (this.props.polyFeature && this.props.polyFeature.length)
    );
  }

  addPolygons() {
    if (!(this.drawControl && this.drawControl.draw && this.needToDraw())) return;
    // First remove last polygon
    this.drawControl.draw.deleteAll();
    // For view only
    this.drawAbleMultiPolygon = this.manipulateGeoAreas(this.props.polyFeature);
    let bounds: any;
    // manipulate geo areas
    if (Array.isArray(this.props.polyFeature)) {
      // parent
      for (let i = 0; i < this.props.polyFeature.length; i++) {
        const child = this.props.polyFeature[i];
        if (!child.geozone) {
          console.log(`No geozone for child - ${child.name}`);
          continue;
        }
        let polyFeature = {
          geometry: {
            coordinates: child.geozone ? child.geozone.coordinates : [],
            type: 'Polygon',
          },
          properties: {
            id: child._id,
            color: this.getRandomColor(),
            name: child.name,
          },
          type: 'Feature',
        };
        this.props.polyFeature[i].feature = polyFeature;
        this.drawControl.draw.add(polyFeature);
      }
      // add also parent geozone
      if (this.props.area.geozone) {
        let parentZone: any = {
          geometry: {
            coordinates: this.props.area.geozone ? this.props.area.geozone.coordinates : [],
            type: 'Polygon',
          },
          properties: {
            id: this.props.area._id,
            color: this.getRandomColor(),
            name: this.props.area.name,
          },
          type: 'Feature',
        };
        this.drawControl.draw.add(parentZone);
      }

      bounds = new mapboxgl.LngLatBounds();

      this.props.polyFeature.forEach(function (feature: any) {
        if (!(feature.geozone && feature.geozone.coordinates && feature.geozone.coordinates.length)) return;
        feature.geozone.coordinates[0].forEach((coords: any) => {
          bounds.extend(coords);
        });
      });
      // add parent geozone to bounds
      if (
        this.props.area.geozone &&
        this.props.area.geozone.coordinates &&
        this.props.area.geozone.coordinates.length
      ) {
        this.props.area.geozone.coordinates[0].forEach((coords: any) => {
          bounds.extend(coords);
        });
      }
    } else {
      // child
      this.drawAbleMultiPolygon = {
        geometry: {
          coordinates: this.props.polyFeature.coordinates,
          type: 'Polygon',
        },
        properties: {
          id: this.props.area._id,
          color: DEFAULT_POLYGON_COLOR,
          name: this.props.area.name,
        },
        type: 'Feature',
      };
      this.drawControl.draw.add(this.drawAbleMultiPolygon);
      bounds = new mapboxgl.LngLatBounds();
      this.props.polyFeature.coordinates[0].forEach((coord: any) => {
        bounds.extend(coord);
      });
    }
    // first add
    if (!this.firstAdd && !bounds.isEmpty()) {
      this.firstAdd = true;
      this.map.state.map.fitBounds(bounds, { duration: 0 });
    }
    this.props.handleChange(this.props.polyFeature);
  }

  // Support here both childs and single polygone
  manipulateGeoAreas(childs: ControlArea[]) {
    if (!(this.props.polyFeature && this.props.polyFeature.length)) return childs;
    const featureCollection: any = {
      type: 'MultiPolygon',
      coordinates: [],
    };
    for (let i = 0; i < childs.length; i++) {
      if (childs[i].geozone && childs[i].geozone.coordinates)
        featureCollection.coordinates.push(childs[i].geozone.coordinates);
    }
    return featureCollection;
  }

  onDrawCreate(e: any) {
    if (!Array.isArray(this.props.polyFeature)) {
      // First remove last polygon
      this.drawControl.draw.deleteAll();
    }
    // Change here?
    this.setState({
      geozone: e.features[0].geometry,
    });
    this.props.handleChange(e.features[0].geometry);
    this.addPolygons();
  }

  onDrawUpdated(e: any) {
    if (Array.isArray(this.props.polyFeature) && e.features[0].properties.id !== this.props.area._id) {
      // parent - should return childs array
      // update child geo
      let index = this.props.polyFeature.findIndex((feature) => {
        return feature._id === e.features[0].properties.id;
      });
      if (index !== -1) {
        this.props.polyFeature[index].geozone = e.features[0].geometry;
      }
      this.props.handleChange(this.props.polyFeature);
    } else {
      // childs - should return geozone or parent polygon change
      this.setState({
        geozone: e.features[0].geometry,
      });
      this.props.handleChange(e.features[0].geometry, e.features[0].properties.id);
    }
  }

  onDrawDeleted(e: any) {
    confirmAlert({
      title: 'Delete Area polygon',
      message: 'Are you sure to delete ' + this.props.area.name + ' polygon?  \n This action cannot be reverted!',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deletePolygon(e.features[0].properties.id),
        },
        {
          label: 'cancel',
          onClick: () => this.addPolygons(),
        },
      ],
    });
  }

  deletePolygon(areaId: string) {
    DeleteControlAreaGeozone(areaId).then(
      () => {
        this.props.handleChange(null, areaId);
      },
      (e: any) => {
        // error
        this.props.handleError(e);
      }
    );
  }

  // render will know everything!
  render() {
    this.controls.polygon = !(this.props.area.children && this.props.area.children.length);
    if (this.needToDraw()) {
      this.drawAbleMultiPolygon = this.manipulateGeoAreas(this.props.polyFeature);
    }
    return (
      <Map
        // eslint-disable-next-line
        style={"mapbox://styles/mapbox/streets-v8"}
        ref={(map: any) => {
          this.map = map;
        }}
        movingMethod="jumpTo"
        onStyleLoad={(map) => {
          this.map = map;
          // world bounds
          if (!this.firstAdd) {
            this.map.fitBounds(
              [
                [-180, -90],
                [180, 90],
              ],
              { duration: 0, zoom: 1 }
            );
          }
          this.map.on('mousemove', (e: any) => {
            const features: any = map.queryRenderedFeatures(e.point);
            let thereArePolygon;
            for (let i = 0; i < features.length; i++) {
              if (features[i].source === 'mapbox-gl-draw-cold') thereArePolygon = true;
              if (
                (features[i].source === 'mapbox-gl-draw-cold' && !this.tooltipFeature) ||
                features[i].source === 'mapbox-gl-draw-cold'
              ) {
                if (!features[i].properties.user_name) continue;
                this.tooltipFeature = features[i];
                this.setTooltip(this.tooltipFeature);
                thereArePolygon = true;
              }
            }
            if (!thereArePolygon) {
              if (this.popup && this.popup.remove) this.popup.remove();
            }
          });
          this.map.on('click', (e: any) => {
            const features: any = map.queryRenderedFeatures(e.point);
            if (!features.length) {
              if (this.popup && this.popup.remove) this.popup.remove();
              return;
            }
          });
        }}
      >
        <DrawControl
          controls={this.controls}
          onDrawUpdate={this.onDrawUpdated}
          onDrawCreate={this.onDrawCreate}
          onDrawDelete={this.onDrawDeleted}
          ref={(drawControl: any) => {
            this.drawControl = drawControl;
            if (!this.isDrawInited) {
              this.isDrawInited = true;
              this.addPolygons();
            }
          }}
          styles={DRAW_MAP_STYLE.style}
          userProperties={true}
        />
      </Map>
    );
  }

  setTooltip(tooltipFeature: any) {
    if (!((this.map && this.map.isMoving) || this.map.state) || this.drawControl.draw.getMode() === 'draw_polygon')
      return;
    if (this.popup && this.popup.remove) this.popup.remove();
    this.popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });
    let centerPoint: any = center(tooltipFeature);
    this.popup
      .setLngLat(centerPoint.geometry.coordinates)
      .setHTML(tooltipFeature.properties.user_name)
      .addTo(this.map && this.map.isMoving ? this.map : this.map.state.map);
  }

  getRandomColor() {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
  }
}
