export enum ZonesStatus {
  DISABLED,
  LOCKED,
  UNLOCKED,
}

export const getAreaStatus = (status?: ZonesStatus) => {
  switch (status) {
    case ZonesStatus.DISABLED:
      return 'Disabled';
    case ZonesStatus.LOCKED:
      return 'Locked';
    case ZonesStatus.UNLOCKED:
      return 'Unlocked';
    default:
      return 'Status unknown';
  }
};
