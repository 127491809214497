import {
  CreateControlArea,
  getControlAreas,
  GetFullControlArea,
  initAreaService,
  UpdateControlArea,
} from 'services/areas.service';
import React, { Component } from 'react';
import { Button, CircularProgress, FilledInput, Grid, InputLabel } from '@material-ui/core';
import { ControlArea } from 'enum/types';
import ParentSelector from '../ParentSelector/ParentSelector.component';
import { AreaMap } from '../AreaMap/AreaMap.component';
import StatusSelector from '../StatusSelector/StatusSelector.component';
import SweetAlert from 'react-bootstrap-sweetalert';
import './AreaForm.css';
import { ZonesStatus } from 'utils/ZoneStatus';
import { GeoJsonDropZone } from 'component/FileUploader/GeoJsonDropZone.component';

export class AreasForm extends Component<any, any> {
  public updateMode: boolean = false;
  public areasNames: string[] = [];

  // can be inited with props as parameter
  constructor(props: any) {
    super(props);
    this.state = {
      area: {
        geozone: {},
        minimumResidents: 100,
        parent: null,
      },
      areas: [],
      isLoading: false,
      errorMessage: null,
      alert: {
        show: false,
        type: null,
        text: '',
        callback: () => {},
      },
    };

    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleMinimumResidentsChange = this.handleMinimumResidentsChange.bind(this);
    this.handleParentChange = this.handleParentChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.polygonSelected = this.polygonSelected.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleChangePremium = this.handleChangePremium.bind(this);
  }

  handleMinimumResidentsChange(e: any) {
    let minimumResidents = parseInt(e.target.value);
    let area: ControlArea = this.state.area || {
      geozone: null,
      parent: null,
      minimumResidents,
    };
    area.minimumResidents = minimumResidents;
    this.setState({ area });
  }

  handleParentChange(parent: any) {
    let area: ControlArea = this.state.area || {
      geozone: null,
      parent: null,
    };
    area.parent = parent;
    this.setState({ area });
  }

  handleFileChange(geozone: any) {
    let area: ControlArea = this.state.area || {
      geozone: null,
      parent: null,
    };
    area.geozone = geozone;
    this.setState({ area });
  }

  // Before the component mounts, we initialise our state
  componentDidMount() {
    if (this.props.location.pathname.indexOf('update') !== -1) {
      // Update mode
      this.setState({
        isLoading: true,
      });
      this.updateMode = true;
      let areaId = this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1];
      initAreaService().then(
        (areas: any) => {
          GetFullControlArea(areaId).then(
            (res: any) => {
              if (res.data) {
                let area = res.data;
                this.setState({
                  area,
                  areas: areas.data,
                  isLoading: false,
                });
              }
            },
            () => {
              this.setState({
                isLoading: false,
              });
            }
          );
        },
        (e: any) => {
          this.setState({
            errorMessage: e.message,
          });
        }
      );
    } else {
      // Create mode
      this.updateMode = false;
    }
    getControlAreas().then((response: any) => {
      if (response.data) {
        this.areasNames = response.data.map((area: ControlArea) => area.name);
      }
    });
  }

  handleStatusChange(status: ZonesStatus) {
    let area = this.state && this.state.area ? this.state.area : {};
    area.status = status;
    this.setState({ area });
  }

  handleChange(event: any) {
    let area = this.state.area || {};
    if (event.target.id === 'name-form') {
      area.name = event.target.value;
    } else if (event.target.id === 'status-form') {
      area.status = event.target.value;
    }
    this.setState({ area });
  }

  handleChangePremium(event: any) {
    let area = this.state.area || {};
    area.premium = event.target.checked;
    this.setState({ area });
  }

  // render will know everything!
  render() {
    return (
      <div className="areas-list">
        {this.state && !this.state.isLoading ? (
          <div>
            <form noValidate autoComplete="off" className="our-form">
              <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
                <div>
                  <div className="column">
                    <InputLabel className="area-form-element" htmlFor="component-filled">
                      Name
                    </InputLabel>
                    <FilledInput
                      id="name-form"
                      className="area-form-element"
                      onChange={this.handleChange}
                      value={this.state.area ? this.state.area.name || '' : ''}
                      required
                    />
                  </div>

                  <div className="column">
                    <InputLabel className="area-form-element" htmlFor="component-filled">
                      Parent
                    </InputLabel>
                    <ParentSelector
                      value={this.state.area && this.state.area.parent ? this.state.area.parent || '' : ''}
                      handleChange={this.handleParentChange}
                    />
                  </div>

                  <div className="column">
                    <InputLabel className="area-form-element" htmlFor="component-filled">
                      Status
                    </InputLabel>
                    <StatusSelector
                      value={this.state.area.status !== undefined ? this.state.area.status || '' : ''}
                      handleChange={this.handleStatusChange}
                    />
                  </div>

                  <div className="column">
                    <InputLabel className="area-form-element" htmlFor="component-filled">
                      Minimum Residents
                    </InputLabel>
                    <FilledInput
                      id="name-form"
                      type="number"
                      className="area-form-element"
                      value={this.state.area.minimumResidents ? this.state.area.minimumResidents || '' : ''}
                      onChange={this.handleMinimumResidentsChange}
                    />
                  </div>
                  {this.state.area && !this.state.area.children ? (
                    <div className="column">
                      <InputLabel className="area-form-element" htmlFor="component-filled">
                        Geozone
                      </InputLabel>
                      <GeoJsonDropZone handleChange={this.handleFileChange} file={this.state.area.geozone} />
                    </div>
                  ) : (
                    ''
                  )}

                  <div className="column">
                    <InputLabel className="area-form-element" htmlFor="component-filled">
                      Premium Zone
                    </InputLabel>
                    <div className="switch-wrapper">
                      <input
                        type="checkbox"
                        className="toggle"
                        checked={this.state.area.premium}
                        onChange={this.handleChangePremium}
                      />
                      <span className="premium-value">{this.state.area.premium ? 'On' : 'Off'}</span>
                    </div>
                  </div>
                </div>

                <Button
                  onClick={this.submitForm}
                  className="submit-button"
                  variant="contained"
                  color="primary"
                  disabled={!(this.state.area.name && this.state.area.name.length)}
                >
                  {this.updateMode ? 'Update' : 'Submit'}
                </Button>
              </Grid>

              <div className="error-message">{this.state.errorMessage ? this.state.errorMessage : ''}</div>

              <AreaMap
                area={this.state.area}
                polyFeature={
                  this.state.area && this.state.area.children && this.state.area.children.length
                    ? this.state.area.children
                    : this.state.area.geozone
                    ? this.state.area.geozone
                    : null
                }
                handleChange={this.polygonSelected}
                hadleError={this.handleError}
              />
            </form>
            {this.state.alert ? (
              <SweetAlert
                show={this.state.alert ? this.state.alert.show : false}
                type={this.state.alert.type}
                title={this.state.alert ? this.state.alert.text : ''}
                onConfirm={
                  this.state.alert && this.state.alert.callback
                    ? this.state.alert.callback
                    : () => this.setState({ show: false })
                }
              />
            ) : (
              ''
            )}
          </div>
        ) : (
          <CircularProgress className="loader" />
        )}
      </div>
    );
  }

  polygonSelected(polygon: any, id?: any) {
    if (!this.state) return;
    let area = this.state.area || {};
    if (!polygon && id) {
      // Delete polygon - need to relize what is the id
      if (area._id === id) {
        // remove parent id
        area.geozone = undefined;
      } else {
        for (let i = 0; i < area.children.length; i++) {
          if (area.children[i]._id === id) {
            area.children[i].geozone = undefined;
          }
        }
      }
    } else if (area.children && area.children.length && id !== area._id) {
      // parent
      area.children = polygon;
    } else {
      // child or parent change
      area.geozone = polygon;
    }
    this.setState({ area });
  }

  submitForm() {
    if (!this.updateMode) {
      if (this.areasNames.includes(this.state.area.name.trimEnd())) {
        this.setState({ errorMessage: 'Cannot create areas with duplicate names.' });
        return;
      }

      CreateControlArea(this.state.area).then(
        () => {
          // Created - back to list
          this.setState({
            alert: {
              show: true,
              type: 'success',
              text: `${this.state.area.name}  was successfully created`,
              callback: () => {
                window.location.href = '/';
              },
            },
          });
        },
        (e: any) => {
          // Error handling
          this.setState({ errorMessage: e.response.data.message });
          console.error('Cannot Create new area');
        }
      );
      return;
    }
    // Update mode
    let reqObj = Object.assign({}, this.state.area);
    UpdateControlArea(reqObj).then(
      () => {
        this.setState({
          alert: {
            show: true,
            type: 'success',
            text: `${this.state.area.name} was successfully updated`,
            callback: () => {
              window.location.href = '/';
            },
          },
        });
        this.setState({ errorMessage: null });
      },
      (e: any) => {
        // Error handling
        console.error('Canot Update new area');
        let message = e.response.data.statusCode === 400 ? e.response.data.message : 'Internal Server error';
        this.setState({ errorMessage: message });
      }
    );
  }

  handleError(e: any) {
    let message = e.response.data.statusCode === 400 ? e.response.data.message : 'Internal Server error';
    this.setState({ errorMessage: message });
  }
}
