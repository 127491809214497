import { Select, MenuItem } from '@material-ui/core';
import React, { Component } from 'react';
import { ZonesStatus } from 'utils/ZoneStatus';

export default class StatusSelector extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      data: [],
      selected: { selected: null },
    };
  }

  componentDidMount() {
    let localData = [];
    // Get const values
    for (let status in ZonesStatus) {
      if (isNaN(Number(status))) {
        localData.push({
          name: status,
          value: ZonesStatus[status],
        });
      }
    }
    this.setState({
      data: localData,
      selected: this.props.value,
    });
  }

  handleChange = (event: any) => {
    this.setState({ selected: event.target.value });
    this.props.handleChange(event.target.value);
  };

  renderOptions() {
    return (
      this.state &&
      this.state.data &&
      this.state.data.length &&
      this.state.data.map((dt: any, i: any) => {
        return (
          <MenuItem key={dt.name} value={dt.value}>
            {dt.name}
          </MenuItem>
        );
      })
    );
  }

  render() {
    return (
      <div className="padd50">
        <Select
          className="width50"
          value={
            this.props.value !== undefined || this.state.selected !== undefined
              ? this.props.value || this.state.selected
              : { name: 'No Status' }
          }
          onChange={this.handleChange}
        >
          {this.renderOptions()}
        </Select>
      </div>
    );
  }
}
