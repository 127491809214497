import {ControlArea} from "enum/types";

export const customSort = (firstArea: ControlArea, secondArea: ControlArea) => {
    let aCalc = Math.min(firstArea.currentResidents / firstArea.minimumResidents, 1);
    let bCalc = Math.min(secondArea.currentResidents / secondArea.minimumResidents, 1);
    let n = aCalc - bCalc;
    if (n !== 0) {
        return n;
    }
    return firstArea.currentResidents - secondArea.currentResidents;
};

export const customFilterAndSearch = (filterValue: string, areas: ControlArea[], area: ControlArea) =>
    getAreaName(areas, area.parent).toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;

const getAreaName = (areas: ControlArea[], areaId: string) => {
    let area = areas.find((area: ControlArea) => areaId === area._id);
    return area ? area.name : '';
};
