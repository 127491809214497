import { ControlArea } from 'enum/types';
import { ZonesStatus } from 'utils/ZoneStatus';

export interface ProcessedAreas {
  usersTotal: number;
  neighborhoods: number;
  processedAreas: ControlArea[];
  parentAreas: { [index: string]: ControlArea };
}

export const prepareProcessedAreas = (areas: ControlArea[]): ProcessedAreas => {
  let usersTotal = 0;
  let neighborhoods = 0;
  let parentAreas: { [index: string]: any } = {};

  areas.forEach((value, index) => {
    const area = areas[index];

    usersTotal += area.currentResidents;

    if (area.status === ZonesStatus.UNLOCKED) {
      neighborhoods++;
    }

    const parentId = area.parent;
    if (parentId && !parentAreas[parentId]) {
      let parentArea = areas.find((area: ControlArea) => parentId === area._id);
      if (parentArea) {
        parentAreas[parentId] = parentArea;
      }
    }
  });

  let processedParentAreas = prepareParentAreas(areas, Object.values(parentAreas));
  let processedAreas = updateAreaArray(areas, processedParentAreas);

  return { usersTotal, processedAreas, neighborhoods, parentAreas };
};

const updateAreaArray = (areas: ControlArea[], updatedAreas: ControlArea[]) =>
  areas.map((area) => {
    let toUpdate = updatedAreas.find((updatedArea) => updatedArea._id === area._id);
    return toUpdate ? { ...area, ...toUpdate } : area;
  });

export const prepareParentAreas = (areas: ControlArea[], parentAreas: ControlArea[]) =>
  parentAreas.map((parentArea) => parentArea && prepareParentArea(parentArea, areas));

export const prepareParentArea = (parentArea: ControlArea, areas: ControlArea[]) => ({
  ...parentArea,
  currentResidents: getCurrentResidents(areas, parentArea),
  minimumResidents: getMinimumResidents(areas, parentArea),
  children: getChildren(areas, parentArea),
});

const getCurrentResidents = (areas: ControlArea[], parentArea: ControlArea) =>
  areas
    .filter((area) => area.parent === parentArea._id)
    .reduce((sum, { currentResidents }) => sum + currentResidents, 0);

const getMinimumResidents = (areas: ControlArea[], parentArea: ControlArea) =>
  areas
    .filter((area) => area.parent === parentArea._id)
    .reduce((sum, { minimumResidents }) => sum + minimumResidents, 0);

const getChildren = (areas: ControlArea[], parentArea: ControlArea) =>
  areas.filter((area) => area.parent === parentArea._id);
