import produce from "immer";
import { ApplicationState, ApplicationAction } from "../enum/types";

export const initialState: ApplicationState = {
  loading: {
    controlAreas: false
  },
  controlAreas: []
};

const reducer = (state = initialState, action: ApplicationAction) => {
  switch (action.type) {
    case "loadAreasRequest":
      return produce(state, draft => {
        draft.loading.controlAreas = true;
      });
    case "loadAreasSuccess":
      return produce(state, draft => {
        draft.loading.controlAreas = false;
        draft.controlAreas = action.controlAreas;
      });
    case "loadAreasError":
      return produce(state, draft => {
        draft.loading.controlAreas = false;
      });
  }
};

export default reducer;
