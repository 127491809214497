import { ControlArea } from 'enum/types';
import React from 'react';
import styled from 'styled-components';

const Premium = ({ area, parent }: { area: ControlArea; parent?: boolean }) => {
  const children = area.children;

  const isPremium = (area: ControlArea) => area.premium;
  const isNotPremium = (area: ControlArea) => !area.premium;

  const allOn = children && children.every(isPremium);
  const allOff = children && children.every(isNotPremium);
  const multiple = children && (children.some(isPremium) && children.some(isNotPremium));

  return (
    <PremiumContainer {...{ parent }}>
      {parent ? (
        <>
          {allOn && 'On'}
          {allOff && 'Off'}
          {multiple && 'Multiple'}
        </>
      ) : (
        <>{area.premium ? 'On' : 'Off'}</>
      )}
    </PremiumContainer>
  );
};

const PremiumContainer = styled.div<{ parent?: boolean }>`
  ${(props) => props.parent && 'font-weight: bolder'}
`;

export default Premium;
