import React from 'react';
import { ControlArea } from 'enum/types';
import DeleteAreaButton from 'component/areas/tableComponents/DeleteAreaButton';
import CurrentMinimum from 'component/areas/tableComponents/CurrentMinimum';
import ChildrenCount from 'component/areas/tableComponents/ChildrenCount';
import Status from 'component/areas/tableComponents/Status';
import { customFilterAndSearch, customSort } from 'utils/tableUtils';
import Premium from 'component/areas/tableComponents/Premium';
import Zone from 'component/areas/tableComponents/Zone';

const columnStyling = {
  cellStyle: { textAlign: 'left' },
  headerStyle: {
    textAlign: 'left',
    fontSize: '.9rem',
    fontWeight: 'bolder',
    textTransform: 'uppercase',
    color: 'black',
  },
};

export const zonesColumn = (areas: ControlArea[], parentAreas: { [index: string]: ControlArea }, ...other: any) => ({
  render: (area: ControlArea) => <Zone area={area} parent={!!parentAreas[area._id]} />,
  title: 'zones',
  field: 'name',
  cellStyle: {...columnStyling.cellStyle, padding: 0},
  headerStyle: { ...columnStyling.headerStyle, paddingLeft: '2rem' },
  ...other,
});

export const childrenColumn = (areas: ControlArea[], parentAreas: { [index: string]: ControlArea }, ...other: any) => ({
  render: (area: ControlArea) => <ChildrenCount area={area} parent={!!parentAreas[area._id]} />,
  customFilterAndSearch: (filter: string, area: ControlArea) => customFilterAndSearch(filter, areas, area),
  title: 'children',
  field: 'childrenCount',
  ...columnStyling,
  ...other,
});

export const statusColumn = (...other: any) => ({
  render: (area: ControlArea) => <Status area={area} />,
  title: 'status',
  field: 'status',
  ...columnStyling,
  ...other,
});

export const currentMinimumColumn = (
  areas: ControlArea[],
  parentAreas: { [index: string]: ControlArea },
  ...other: any
) => ({
  render: (area: ControlArea) => <CurrentMinimum area={area} parent={!!parentAreas[area._id]} />,
  customSort: (firstArea: ControlArea, secondArea: ControlArea) => customSort(firstArea, secondArea),
  title: 'current / minimum',
  field: 'minimumResidents',
  ...columnStyling,
  ...other,
});

export const premiumColumn = (areas: ControlArea[], parentAreas: { [index: string]: ControlArea }, ...other: any) => ({
  render: (area: ControlArea) => <Premium area={area} parent={!!parentAreas[area._id]} />,
  title: 'premium',
  field: 'premium',
  ...columnStyling,
  ...other,
});

export const updatedColumn = (...other: any) => ({
  render: (area: ControlArea) => <div>{new Date(area.updated).toLocaleDateString()}</div>,
  title: 'updated',
  field: 'updated',
  ...columnStyling,
  ...other,
});

export const deleteAreaColumn = (
  areas: ControlArea[],
  setDeleteHovered: (isHovered: boolean) => void,
  ...other: any
) => ({
  render: (controlArea: ControlArea) => (
    <DeleteAreaButton controlArea={controlArea} setDeleteHovered={setDeleteHovered} />
  ),
  title: 'delete',
  field: 'status',
  ...columnStyling,
  ...other,
});
