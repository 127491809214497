import { ZonesStatus } from 'utils/ZoneStatus';

export const Config = {
  API_URL: process.env.REACT_APP_API_URL || 'https://api.stg.globekeeper.com',
  LOGIN_URL: process.env.REACT_APP_LOGIN_URL || 'https://app.stg.globekeeper.com',
  API_TOKEN: process.env.REACT_APP_API_TOKEN || '',
  MAPBOX_ACCESS_TOKEN:
    process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ||
    'pk.eyJ1IjoiZGFucGUiLCJhIjoiY2pmM2Jlc2NqMDB0czJ4bnZ4YnJ6ZDAxOCJ9.pNdfh7p41_nyTSxbtvj49A',
  Services: {
    Areas: '/areas',
    Me: '/me',
  },
  ZonesStatus,
};
