import { ControlArea } from 'enum/types';
import React from 'react';
import styled from 'styled-components';

const ChildrenCount = ({ area, parent }: { area: ControlArea; parent?: boolean }) => (
  <ChildrenCountContainer {...{ parent }}>{area.children ? area.children.length : '-'}</ChildrenCountContainer>
);

const ChildrenCountContainer = styled.div<{ parent?: boolean }>`
  ${(props) => props.parent && 'font-weight: bolder'}
`;

export default ChildrenCount;
