import UserInfo from 'component/UserInfo';
import logo from 'assets/logo.jpg';
import React from 'react';
import styled from 'styled-components';
import ZonesNavigation from 'component/header/ZonesNavigation';

const Header = () => (
  <>
    <StyledHeader>
      <Container>
        <UserInfo />
      </Container>
      <Container justifyContent="center">
        <TitleImage src={logo} alt="logo" />
      </Container>
      <Container justifyContent="flex-end">
        <ReportRequestLink
          href="https://forms.monday.com/forms/8140e0c60347e349009845e54db12a89"
          target="_blank"
          rel="noopener noreferrer"
        >
          Report a Bug / Request a Feature
        </ReportRequestLink>
      </Container>
    </StyledHeader>
    <ZonesNavigation />
  </>
);

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: center;
  position: relative;
  padding: 1rem 0 0.3rem 0;
  pointer-events: none;
`;

const Container = styled.div<{ justifyContent?: string }>`
  display: flex;
  width: 33%;
  ${(props) => props.justifyContent && `justify-content: ${props.justifyContent}`}
`;

const TitleImage = styled.img`
  display: block;
  max-height: 120px;
  padding-bottom: 20px;
`;

const ReportRequestLink = styled.a`
  align-self: end;
  margin: 0 1rem;
`;

export default Header;
