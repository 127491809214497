import axios from 'axios';
import { Config } from 'utils/config';

let currentUser: any;
export const makeRequest = (url: string, data: any, method: any, headers = null, withCredentials = true): any => {
  if (!method) return console.error('Please provide a method!');
  let reqOptions = {
    body: data,
    withCredentials,
  };
  // validate token
  if (!axios.defaults.headers.common['Authorization']) setToken();
  switch (method) {
    case 'GET':
      return axios.get(Config.API_URL + url, reqOptions);
    case 'POST':
      return axios.post(Config.API_URL + url, data, reqOptions);
    case 'DELETE':
      return axios.delete(Config.API_URL + url, reqOptions);
    case 'PUT':
      return axios.put(Config.API_URL + url, data, reqOptions);
    default:
      return null;
  }
};

function setToken() {
  axios.defaults.headers.common['Authorization'] = Config.API_TOKEN;
}

export interface User {
  entityid: string;
  extras: any;
  name: string;
}

export const getAuthenticatedUser = () => {
  return new Promise<User>((resolve, reject) => {
    if (currentUser) resolve(currentUser);
    else {
      makeRequest(Config.Services.Me, null, 'GET').then(
        (res: any) => {
          currentUser = res.data;
          resolve(currentUser);
        },
        (e: any) => {
          let login = Config.LOGIN_URL || 'http://127.0.0.1:5002/#';
          if (!login.endsWith('/#/login')) {
            login = login + '/#/login';
          }
          window.location.href = login + '/?redirect=' + window.location.href;
          reject(e);
        }
      );
    }
  });
};
