import React, { useEffect, useState } from 'react';
import { ControlArea } from 'enum/types';
import { getControlAreas } from 'services/areas.service';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import AreaTable from 'component/areas/AreaTable';
import { prepareProcessedAreas } from 'utils/processedAreas';

const pkg = require('../../../package.json');

const Areas = () => {
  const [fetchingAreas, setFetchingAreas] = useState(false);
  const [areas, setAreas] = useState<ControlArea[]>();

  useEffect(() => {
    setFetchingAreas(true);

    getControlAreas().then(
      (response: any) => {
        setAreas(response.data ? response.data : response);
        setFetchingAreas(false);
      },
      (error: any) => {
        console.warn(error);
        setFetchingAreas(false);
      }
    );
  }, []);

  return (
    <StyledAreas>
      {fetchingAreas && <Loader />}
      {areas && <AreaTable processedAreas={prepareProcessedAreas(areas)} />}
      <div className="version">{'GlobeKeeper ZonesManager v' + pkg.version}</div>
    </StyledAreas>
  );
};

const StyledAreas = styled.div`
  margin: 0 auto;
  text-align: center;

  .table-title {
    font-size: 20px;
    padding: 10px;
  }

  .MuiToolbar-root .MuiInput-underline {
    width: 25vw;
    height: 4rem;
    margin-right: 2rem;
    font-size: 1.3rem;
    background-color: #ffffff !important;
  }
  
`;

const Loader = styled(CircularProgress)`
  padding: 2rem 0;
`;

export default Areas;
