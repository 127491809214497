import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';

const ZonesNavigation = () => (
  <FlexContainer>
    <Button variant="contained" color="primary">
      <Link to="/">Areas List</Link>
    </Button>
    <Button variant="contained" color="primary">
      <Link to="/add">Add new Area</Link>
    </Button>
  </FlexContainer>
);

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export default ZonesNavigation;
