import React, {useLayoutEffect} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {AreasForm} from 'component/AreaForm/AreaForm.component';
import {initAreaService} from 'services/areas.service';
import Header from 'component/header/Header';
import Areas from 'component/areas/Areas';
import 'App.css';

const Root = () => {
  useLayoutEffect(() => {
    initAreaService();
  }, []);

  return (
    <Router>
      <Header />
      <Switch>
        <Route path="/update" component={AreasForm} />
        <Route path="/add" component={AreasForm} />
        <Route path="/" component={Areas} />
      </Switch>
    </Router>
  );
};

export default Root;
