import React from 'react';
import { ControlArea } from 'enum/types';
import styled from 'styled-components';
import colors from 'assets/styles/colors';

const Zone = ({ area, parent }: { area: ControlArea; parent?: boolean }) => (
  <ZoneContainer {...{ parent }}>
    {parent && <BlueBlock />}
    {area.name}
  </ZoneContainer>
);

const ZoneContainer = styled.div<{ parent?: boolean }>`
  display: flex;
  align-items: center;
  font-weight: ${(props) => props.parent && 'bolder'};
  padding: ${(props) => (props.parent ? '0' : '0 2rem')};
`;

const BlueBlock = styled.span`
  display: inline-block;
  width: 0.3rem !important;
  height: 4rem;
  margin-right: 1.8rem;
  background-color: ${colors.gkBlue} !important;
  border-radius: 2px;
`;

export default Zone;
