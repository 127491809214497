import { ControlArea } from 'enum/types';
import { Button } from '@material-ui/core';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { DeleteControlArea } from 'services/areas.service';

const DeleteAreaButton = ({
  controlArea,
  setDeleteHovered,
}: {
  controlArea: ControlArea;
  setDeleteHovered: (isHovered: boolean) => void;
}) => {
  const confirmDeleteArea = () => {
    confirmAlert({
      title: 'Delete Area',
      message: 'Are you sure to delete ' + controlArea.name,
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteArea(),
        },
        {
          label: 'cancel',
          onClick: () => null,
        },
      ],
    });
  };

  const deleteArea = () => {
    DeleteControlArea(controlArea).then(
      () => {
        console.log(`Area ${controlArea._id} deleted. Fetching areas...`)
        window.location.reload();
      },
      () => {
        console.error('Failed to delete area');
      }
    );
  };

  return (
    <Button
      variant="contained"
      disabled={controlArea.currentResidents > 0}
      color="secondary"
      type="submit"
      onClick={confirmDeleteArea}
      onMouseEnter={() => setDeleteHovered(true)}
      onMouseLeave={() => setDeleteHovered(false)}
    >
      Delete
    </Button>
  );
};

export default DeleteAreaButton;
