import React, { Component } from 'react';
import Dropzone from 'react-dropzone-uploader';

export class GeoJsonDropZone extends Component<any, any> {
  public styles: any = {};
  constructor(props: any) {
    super(props);
    this.state = {
      file: null,
      fileTypeError: false,
    };
  }

  handleChangeStatus = ({ file }: any) => {
    let reader = new FileReader();
    reader.onload = (e: any) => {
      let res: any = JSON.parse(e.target.result);
      if (res.features && res.features[0] && res.features[0].geometry.type === 'Polygon') {
        this.setState({ file: res.features[0].geometry, fileTypeError: false });
        this.props.handleChange(res.features[0].geometry);
        return;
      }
      this.setState({
        file: null,
        fileTypeError: true,
      });
    };
    reader.readAsText(file);
  };

  render() {
    return (
      <div style={{ maxWidth: '122px' }}>
        <div className="dropzone-container" style={{ backgroundColor: this.state.fileTypeError ? 'red' : 'green' }}>
          <Dropzone
            onChangeStatus={this.handleChangeStatus}
            accept=".geojson, .json"
            inputContent="Drag GeoJson file or Click to Browse"
            PreviewComponent={(props) => <div>{props.files[0].file.name}</div>}
            multiple={false}
            maxFiles={1}
          />
        </div>
        {this.state.fileTypeError ? <span className="file-type-error">Only polygon areas supported.</span> : ''}
      </div>
    );
  }
}
