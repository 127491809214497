import { Config } from 'utils/config';
import { makeRequest } from './webApi.service';
import { ControlArea } from 'enum/types';

let areaDic: any = {};
let areas: ControlArea[] = [];
let reqRef: any;

export const initAreaService = (): any => {
  return new Promise((resolve, reject) => {
    getControlAreas().then(
      (res: any) => {
        initAreasDic(res.data);
        resolve(res.data);
      },
      () => {
        // error handling
        console.error('Cannot get control areas!');
        reject();
      }
    );
  });
};

export const getControlAreas = (): Promise<ControlArea[]> => {
  if (areas && areas.length) {
    return new Promise((resolve) => {
      resolve(areas);
    });
  }
  if (reqRef) return reqRef;
  reqRef = makeRequest(Config.Services.Areas, null, 'GET', null, true);
  return reqRef;
};

export const GetControlAreaById = (id: string): ControlArea => {
  return areaDic[id];
};

export const GetFullControlArea = (id: string): Promise<ControlArea> => {
  return makeRequest(Config.Services.Areas + '/' + id, null, 'GET');
};

export const UpdateControlArea = (controlArea: any) => {
  let parsedControlArea = controlArea;
  delete parsedControlArea.path;
  return makeRequest(Config.Services.Areas + '/' + controlArea._id, parsedControlArea, 'PUT', null, true);
};

export const CreateControlArea = (controlArea: ControlArea) => {
  return makeRequest(Config.Services.Areas, controlArea, 'POST', null, true);
};

export const DeleteControlArea = (controlArea: ControlArea) => {
  return makeRequest(Config.Services.Areas + '/' + controlArea._id, null, 'DELETE', null, true);
};

export const DeleteControlAreaGeozone = (controlAreaId: string) => {
  return makeRequest(Config.Services.Areas + '/' + controlAreaId + '/geozone', null, 'DELETE', null, true);
};

function initAreasDic(incomingAreas: ControlArea[]) {
  if (!(incomingAreas && incomingAreas.length)) return;
  areas = incomingAreas;
  areaDic = {};
  for (let i = 0; i < areas.length; i++) {
    areaDic[areas[i]._id] = areas[i];
  }
}
