import { ControlArea } from 'enum/types';
import { getAreaStatus, ZonesStatus } from 'utils/ZoneStatus';
import React from 'react';
import styled from 'styled-components';
import colors from 'assets/styles/colors';

const Status = ({ area }: { area: ControlArea }) => {
  const unlocked = area.status === ZonesStatus.UNLOCKED;

  return <StatusContainer {...{ unlocked }}>{getAreaStatus(area.status)}</StatusContainer>;
};

const StatusContainer = styled.div<{ unlocked?: boolean }>`
  ${(props) => props.unlocked && `color: ${colors.gkBlue}`}
`;

export default Status;
