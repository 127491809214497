import React, { useEffect, useState } from 'react';
import { getAuthenticatedUser, User } from 'services/webApi.service';
import styled from 'styled-components';

const DEFAULT_IMAGE = require('assets/avatar.png');

const UserInfo = () => {
  const [fetchingUser, setFetchingUser] = useState(false);
  const [user, setUser] = useState<User>();

  useEffect(() => {
    setFetchingUser(true);
    getAuthenticatedUser().then(
      (user: User) => {
        setUser(user);
        setFetchingUser(false);
      },
      (error: any) => {
        console.warn(error);
        setFetchingUser(false);
      }
    );
  }, []);

  const userPicture = user && user.extras.picture ? user.extras.picture : DEFAULT_IMAGE;
  const userName = user && `${user.name} (${user.entityid})`;

  return (
    <UserContainer>
      <UserPicture alt="hey" src={userPicture} />
      <UserName>Hey {fetchingUser ? '' : userName}</UserName>
    </UserContainer>
  );
};
const UserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: start;
  margin: 0 5rem 0.5rem 0.5rem;
`;

const UserPicture = styled.img`
  height: 35px;
  width: 35px;
  border: 1px solid #979797;
  border-radius: 47px;
  object-fit: cover;
  transition: 0.2s ease;
  backface-visibility: hidden;
  margin: 0 0.5rem !important;
`;

const UserName = styled.div`
  display: flex;
`;

export default UserInfo;
